import { ClipPath, Defs, LinearGradient, Path, Stop, Svg, G, Rect } from "@react-pdf/renderer";
import React from "react";

export default function LogoSmall(props) {
	return (
	  <Svg
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 500 500"
			style={{
				enableBackground: "new 0 0 500 500",
				width: 30,
				height: 30
			}}
			xmlSpace="preserve"
			{...props}
		>
			<Rect fill="#000" width={500} height={500} />

			<Defs>
				<LinearGradient
					id="SVGID_1_"
					x1={325.9957}
					y1={361.3429}
					x2={325.9957}
					y2={138.9376}
				>
					<Stop
						offset={0.04943783}
						style={{
							stopColor: "#5677FF",
						}}
					/>
					<Stop
						offset={0.1781}
						style={{
							stopColor: "#89FE8A",
						}}
					/>
					<Stop
						offset={0.3831}
						style={{
							stopColor: "#FFFF83",
						}}
					/>
					<Stop
						offset={0.8435}
						style={{
							stopColor: "#FFFF83",
						}}
					/>
					<Stop
						offset={1}
						style={{
							stopColor: "#E8E283",
						}}
					/>
				</LinearGradient>
			</Defs>

			<Path
				fill="#FFF"
				d="M65.3,148.4h50.4v158l100.8-0.1v42.8H65.3V148.4z"
			/>
			<Path
				fill="#FFF"
				d="M431.4,248.1c0-57.5-46.8-104.2-104.9-105.2c-58.2,1.1-105,48.5-105,107c0,33.7,15.6,63.7,39.9,83.3 c17.7,12.7,39.5,20.2,63.1,20.2C383.5,353.4,431.4,306.3,431.4,248.1z"
			/>
			<Path
				id="SVGID_4_"
				d="M435.4,249.9c0,59.1-47.9,107-107,107c-25.4,0-48.7-8.8-67-23.6c16.2,10,35.4,15.8,55.9,15.8 c58,0,105-46.2,105-103.1c0-55.1-44.1-100.1-99.5-103c1.9-0.1,3.7-0.1,5.6-0.1C387.5,142.9,435.4,190.8,435.4,249.9z"
			/>
			<Rect
				x={214.8}
				y={138.7}
				clipPath="#SVGID_1_"
				width={222.4}
				height={222.4}
			/>
		</Svg>
	);
}