import React from "react"
import Navbar from "./../components/Navbar";
import { Switch, Route, Router as UtilRouter } from "./../util/router";
import Footer from "./../components/Footer";
import routes from "./routes";
import { Redirect } from "react-router-dom";
import { useAuth } from "../util/auth";
import AuthPage from "../pages/auth";
import { Box, CircularProgress, Dialog } from "@material-ui/core";
import CompleteAccountPage from "../pages/complete-account";

export default function Router() {
  const auth = useAuth();
  
  return (
    <UtilRouter>
      <Navbar
        color="default"
        logo="https://uploads.divjoy.com/logo.svg"
        logoInverted="https://uploads.divjoy.com/logo-white.svg"
      />

      <Dialog open={!auth.user && !auth.loggedOut}>
        <Box py={5} px={8} align="center" alignItems="center">
          <CircularProgress size={32} color="white" />
          <h2 style={{ marginBottom: 0 }}>Authenticating</h2>
        </Box>
      </Dialog>

      {auth.user !== null && (
        <Switch>
          
          {routes.map((route, i) => (
            route.private ? (
              auth.user && (
                <Route
                  key={i}
                  exact
                  path={route.path} 
                  component={auth.user.pvt?.phn ? route.component : () => <Redirect to="/complete-account"/>}
                />
              )
            ) : (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            )
          ))}
  
          {auth.user && (
            <Route
              exact
              path="/complete-account"
              component={CompleteAccountPage}
            />
          )}
  
          <Route 
            exact
            path="/auth/:type"
            component={!auth.loggedOut ? () => <Redirect to="/dashboard"/> : AuthPage}
          />
  
          <Route component={() => <Redirect to={{ pathname: `${auth.user ? '/dashboard' : '/auth/signin' }` }} />}/>

        </Switch>
      )}

      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        description="A short description of what you do here"
        copyright={`© ${new Date().getFullYear()} Load Out Limited`}
        logo="https://uploads.divjoy.com/logo.svg"
        logoInverted="https://uploads.divjoy.com/logo-white.svg"
        sticky={true}
      />
    </UtilRouter>
  )
}