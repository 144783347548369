import IndexPage from '../pages';
import AboutPage from '../pages/about';
import FaqPage from '../pages/faq';
import ContactPage from '../pages/contact';
import PricingPage from '../pages/pricing';
import DashboardPage from '../pages/dashboard';
import SettingsPage from '../pages/settings';
import LegalPage from '../pages/legal';
import * as TourPages from '../pages/tour';
import * as PackPages from '../pages/pack';
import * as ItemPages from '../pages/item';

const routes = [
  { 
    path: "/",
    component: IndexPage 
  },
  { 
    path: "/about",
    component: AboutPage 
  },
  { 
    path: "/faq",
    component: FaqPage 
  },
  { 
    path: "/contact",
    component: ContactPage 
  },
  { 
    path: "/pricing",
    component: PricingPage 
  },
  { 
    path: "/dashboard",
    component: DashboardPage,
    private: true
  },
  { 
    path: "/settings/:section",
    component: SettingsPage,
    private: true
  },
  { 
    path: "/legal/:section",
    component: LegalPage
  },
  /**
   * Tours
   */
  {
    path: "/tours",
    component: TourPages.Listing,
    private: true
  },
  {
    path: "/tours/create",
    component: TourPages.AddEdit,
    private: true
  },
  {
    path: "/tours/:id",
    component: TourPages.View,
    private: true
  },
  {
    path: "/tours/:id/edit",
    component: TourPages.AddEdit,
    private: true
  },
  /**
   * Packs
   */
  {
    path: "/packs",
    component: PackPages.Listing,
    private: true
  },
  {
    path: "/packs/create",
    component: PackPages.AddEdit,
    private: true
  },
  {
    path: "/packs/:id",
    component: PackPages.View,
    private: true
  },
  {
    path: "/packs/:id/edit",
    component: PackPages.AddEdit,
    private: true
  },
  /**
   * Items
   */
  {
    path: "/items",
    component: ItemPages.Listing,
    private: true
  },
  {
    path: "/items/create",
    component: ItemPages.AddEdit,
    private: true
  },
  {
    path: "/items/:id",
    component: ItemPages.View,
    private: true
  },
  {
    path: "/items/:id/edit",
    component: ItemPages.AddEdit,
    private: true
  }
];

export default routes;