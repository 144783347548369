import React from "react";
import { Grid, Paper, Container } from "@material-ui/core";

export default function DetailList({ items }) {
  return (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        {items.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Paper>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={6}>{item.label}</Grid>
                  <Grid item xs={6}>{item.value}</Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}