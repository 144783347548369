import React from "react";
import { PDFViewer, Document, Page } from '@react-pdf/renderer';
import QRCode from "./QRCode";

export default function QRCodePrintPreview({ pageSize = 'A5', qrCodes }) {
  return (
    <PDFViewer height="100%" width="100%">
      <Document>
        <Page size={pageSize}>
          {qrCodes.map(({ slug, name }, i) => (
            <QRCode key={i} slug={slug} name={name} />
          ))}
        </Page>
      </Document>
    </PDFViewer>
  );
}