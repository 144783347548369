import React, { useMemo, useState } from "react";
import { 
  Box, 
  Button, 
  CircularProgress,
  Divider, 
  IconButton, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemSecondaryAction,
  ListItemText, 
  Paper, 
  Typography 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useRouter } from "../util/router";
import { deleteItem } from "../actions/item";
import Checkbox from '@mui/material/Checkbox';
import QRCodePrintPreview from "../components/QRCodePrintPreview";
import Modal from '../components/Modal';

export default function ItemList({ items, itemsStatus }) {

  const [selectedItems, setSelectedItems] = useState([]);
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  const router = useRouter();

  const itemsAreEmpty = !items || Object.keys(items).length === 0;

  const handleDeleteClicked = itemId => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this item?')) {
      deleteItem(itemId);
    }
  }

  const handleItemChecked = id => {
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter(v => v !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
  }

  const selectedItemQrCodes = useMemo(() => {
    if (!items) return;

    return items.filter(i => selectedItems.includes(i.id))
      .map(item => ({ slug: `/${item.id}`, name: item.det.nm }));
  }, [items, selectedItems]);

  return (
    <>
      <Paper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Items</Typography>
          
          <div>
            {selectedItems.length > 0 && (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setShowPrintPreview(true)}
                style={{ marginRight: '1rem' }}
              >
                Print QR Codes
              </Button>
            )}

            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => router.push('/items/create')}
            >
              Create Item
            </Button>
          </div>
        </Box>

        <Divider />

        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <Box py={5} px={3} align="center">
            {itemsStatus === "loading" && <CircularProgress size={32} />}

            {itemsStatus !== "loading" && itemsAreEmpty && (
              <>Nothing yet. Click the button to add your first item.</>
            )}
          </Box>
        )}

        {itemsStatus !== "loading" && items?.length > 0 && (
          <List disablePadding={true}>
            {items.map((item, index) => (
              <ListItem
                key={index}
                divider={index !== items.length - 1}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedItems.includes(item.id)}
                    onClick={() => handleItemChecked(item.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText>
                  <Button to={`/items/${item.id}`} component={Link}>
                    {item.det.nm}
                  </Button>
                </ListItemText>
                <ListItemText>{item.det.snm}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="update"
                    onClick={() => router.push(`/items/${item.id}/edit`)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteClicked(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>

      {items && selectedItemQrCodes && (
        <Modal 
          title="Print QR Codes" 
          open={showPrintPreview} 
          onClose={() => setShowPrintPreview(false)}
        >
          <div style={{ height: '80vh' }}>
            <QRCodePrintPreview qrCodes={selectedItemQrCodes} />
          </div>
        </Modal>
      )}
    </>
  );
}