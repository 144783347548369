import React from "react"
import { Path, Svg, G, Rect, Polygon } from "@react-pdf/renderer";

export default function LogoQR({ props }) {
	return (
		<Svg
			id="Layer_1"
			viewBox="0 0 290.64 30.15"
			style={{
				enableBackground: "new 0 0 290.64 30.15",
				width: 240
			}}
			xmlSpace="preserve"
			{...props}
		>
			<G>
				<Polygon
					fill="#808285"
					points="159.77,15.96 159.77,25.8 164.55,25.8 164.55,24.75 161.07,24.75 161.07,15.96  "
				/>
				<Path
					fill="#808285"
					d="M170.45,25.89c-2.8,0-5-2.08-5-5.03c0-2.95,2.2-5.03,5-5.03c2.81,0,5,2.08,5,5.03 C175.45,23.82,173.27,25.89,170.45,25.89 M170.45,24.78c2.1,0,3.67-1.51,3.67-3.91c0-2.41-1.57-3.91-3.67-3.91s-3.67,1.5-3.67,3.91 C166.78,23.27,168.35,24.78,170.45,24.78"
				/>
				<Path
					fill="#808285"
					d="M183.05,23.61h-4.34l-0.8,2.19h-1.37l3.6-9.78h1.5l3.59,9.78h-1.37L183.05,23.61z M180.88,17.59l-1.8,4.97h3.6 L180.88,17.59z"
				/>
				<Path
					fill="#808285"
					d="M195.18,20.9c0,3.05-2.01,4.9-5.29,4.9h-3.1v-9.84h3.1C193.17,15.96,195.18,17.85,195.18,20.9 M189.89,24.74 c2.6,0,3.96-1.44,3.96-3.84s-1.36-3.9-3.96-3.9h-1.8v7.74H189.89z"
				/>
				<Path
					fill="#808285"
					d="M201.41,25.89c-2.8,0-5-2.08-5-5.03c0-2.95,2.2-5.03,5-5.03c2.81,0,5,2.08,5,5.03 C206.41,23.82,204.22,25.89,201.41,25.89 M201.41,24.78c2.1,0,3.67-1.51,3.67-3.91c0-2.41-1.57-3.91-3.67-3.91s-3.67,1.5-3.67,3.91 C197.74,23.27,199.31,24.78,201.41,24.78"
				/>
				<Path
					fill="#808285"
					d="M208.09,15.96h1.3v6.23c0,1.81,0.99,2.58,2.46,2.58c1.47,0,2.44-0.78,2.44-2.58v-6.23h1.3v6.21 c0,2.56-1.7,3.73-3.76,3.73c-2.06,0-3.75-1.17-3.75-3.73V15.96z"
				/>
				<Polygon
					fill="#808285"
					points="217.15,15.96 223.9,15.96 223.9,17 221.18,17 221.18,25.8 219.88,25.8 219.88,17 217.15,17  "
				/>
				<Path
					fill="#808285"
					d="M231.38,23.61h-4.34l-0.8,2.19h-1.37l3.6-9.78h1.5l3.59,9.78h-1.37L231.38,23.61z M229.21,17.59l-1.8,4.97h3.6 L229.21,17.59z"
				/>
				<Path
					fill="#808285"
					d="M238.34,21.69h-1.92v4.11h-1.3v-9.84h3.22c2.34,0,3.44,1.27,3.44,2.88C241.78,20.33,240.81,21.69,238.34,21.69  M238.34,20.63c1.49,0,2.12-0.69,2.12-1.79c0-1.14-0.63-1.82-2.12-1.82h-1.92v3.61H238.34z"
				/>
				<Path
					fill="#808285"
					d="M246.61,21.69h-1.92v4.11h-1.3v-9.84h3.22c2.34,0,3.44,1.27,3.44,2.88C250.05,20.33,249.08,21.69,246.61,21.69  M246.61,20.63c1.48,0,2.12-0.69,2.12-1.79c0-1.14-0.63-1.82-2.12-1.82h-1.92v3.61H246.61z"
				/>
				<Path
					fill="#808285"
					d="M252.08,25.88c-0.5,0-0.89-0.38-0.89-0.87c0-0.5,0.38-0.87,0.89-0.87c0.47,0,0.86,0.38,0.86,0.87 C252.93,25.5,252.55,25.88,252.08,25.88"
				/>
				<Path
					fill="#808285"
					d="M259.15,15.83c2.16,0,3.87,1.07,4.62,2.98h-1.56c-0.54-1.18-1.6-1.85-3.06-1.85c-2.09,0-3.64,1.5-3.64,3.91 c0,2.4,1.56,3.9,3.64,3.9c1.46,0,2.52-0.66,3.06-1.84h1.56c-0.74,1.89-2.46,2.95-4.62,2.95c-2.79,0-4.97-2.06-4.97-5.01 C254.18,17.92,256.37,15.83,259.15,15.83"
				/>
				<Path
					fill="#808285"
					d="M270.21,25.89c-2.8,0-5-2.08-5-5.03c0-2.95,2.2-5.03,5-5.03c2.81,0,5,2.08,5,5.03 C275.21,23.82,273.02,25.89,270.21,25.89 M270.21,24.78c2.1,0,3.67-1.51,3.67-3.91c0-2.41-1.57-3.91-3.67-3.91s-3.67,1.5-3.67,3.91 C266.54,23.27,268.11,24.78,270.21,24.78"
				/>
				<Polygon
					fill="#808285"
					points="276.92,16.03 278.32,16.03 281.98,24.1 285.64,16.03 287.03,16.03 287.03,25.8 285.72,25.8  285.72,18.51 282.44,25.8 281.52,25.8 278.22,18.5 278.22,25.8 276.92,25.8  "
				/>
				<Path
					fill="#58595b"
					d="M163.67,14c-2.04,0-3.61-1.05-3.63-2.88h2.11c0.06,0.78,0.58,1.29,1.49,1.29c0.93,0,1.47-0.48,1.47-1.18 c0-2.09-5.06-0.83-5.04-4.34c0-1.75,1.43-2.8,3.45-2.8c2.01,0,3.38,1.01,3.51,2.76h-2.16c-0.04-0.64-0.56-1.14-1.4-1.15 c-0.77-0.03-1.35,0.35-1.35,1.14c0,1.94,5.02,0.86,5.02,4.27C167.14,12.63,165.9,14,163.67,14"
				/>
				<Path
					fill="#58595b"
					d="M173.33,4.1c2.18,0,3.95,1.15,4.6,3.13h-2.26c-0.46-0.93-1.3-1.39-2.36-1.39c-1.71,0-2.94,1.23-2.94,3.2 c0,1.95,1.22,3.2,2.94,3.2c1.05,0,1.89-0.46,2.36-1.4h2.26c-0.66,2-2.43,3.13-4.6,3.13c-2.82,0-4.97-2.04-4.97-4.94 C168.36,6.15,170.51,4.1,173.33,4.1"
				/>
				<Path
					fill="#58595b"
					d="M185.63,12.06h-3.9l-0.65,1.84h-2.06l3.52-9.69h2.29l3.52,9.69h-2.08L185.63,12.06z M183.68,6.46l-1.42,4.05 h2.84L183.68,6.46z"
				/>
				<Polygon
					fill="#58595b"
					points="198.06,4.21 198.06,13.9 196.09,13.9 191.64,7.26 191.64,13.9 189.68,13.9 189.68,4.21 191.64,4.21  196.09,10.87 196.09,4.21  "
				/>
				<Polygon
					fill="#58595b"
					points="203.33,4.22 209.38,4.22 209.38,5.79 205.3,5.79 205.3,8.27 208.43,8.27 208.43,9.81 205.3,9.81  205.3,13.9 203.33,13.9  "
				/>
				<Path
					fill="#58595b"
					d="M215.32,14c-2.77,0-5.03-2.05-5.03-4.96c0-2.9,2.26-4.95,5.03-4.95c2.79,0,5.01,2.05,5.01,4.95 C220.34,11.95,218.1,14,215.32,14 M215.32,12.27c1.77,0,2.99-1.26,2.99-3.23c0-1.97-1.22-3.21-2.99-3.21 c-1.78,0-3.01,1.24-3.01,3.21C212.32,11,213.54,12.27,215.32,12.27"
				/>
				<Path
					fill="#58595b"
					d="M225.47,4.22c2.36,0,3.54,1.35,3.54,2.97c0,1.18-0.66,2.37-2.23,2.79l2.35,3.92h-2.27l-2.16-3.77h-0.93v3.77 h-1.96V4.22H225.47z M225.41,5.83h-1.64v2.84h1.64c1.1,0,1.58-0.57,1.58-1.44C226.99,6.37,226.5,5.83,225.41,5.83"
				/>
				<Path
					fill="#58595b"
					d="M242.73,9.08c0,2.93-2.03,4.83-5.17,4.83h-3.43V4.22h3.43C240.69,4.22,242.73,6.14,242.73,9.08 M237.49,12.25 c2.07,0,3.23-1.16,3.23-3.17c0-2.01-1.17-3.22-3.23-3.22h-1.39v6.39H237.49z"
				/>
				<Polygon
					fill="#58595b"
					points="249.89,5.79 246.17,5.79 246.17,8.22 249.47,8.22 249.47,9.76 246.17,9.76 246.17,12.32 249.89,12.32  249.89,13.9 244.21,13.9 244.21,4.21 249.89,4.21  "
				/>
				<Polygon
					fill="#58595b"
					points="251.16,4.22 258.34,4.22 258.34,5.79 255.73,5.79 255.73,13.9 253.77,13.9 253.77,5.79 251.16,5.79  "
				/>
				<Path
					fill="#58595b"
					d="M265.78,12.06h-3.9l-0.65,1.84h-2.06l3.52-9.69h2.29l3.52,9.69h-2.08L265.78,12.06z M263.83,6.46l-1.42,4.05 h2.84L263.83,6.46z"
				/>
				<Rect x={269.83} y={4.22} fill="#58595b" width={1.97} height={9.68} />
				<Polygon
					fill="#58595b"
					points="275.7,4.22 275.7,12.36 278.92,12.36 278.92,13.9 273.73,13.9 273.73,4.22  "
				/>
				<Path
					fill="#58595b"
					d="M283.56,14c-2.04,0-3.61-1.05-3.64-2.88h2.11c0.06,0.78,0.58,1.29,1.49,1.29c0.93,0,1.47-0.48,1.47-1.18 c0-2.09-5.05-0.83-5.04-4.34c0-1.75,1.43-2.8,3.45-2.8c2.01,0,3.38,1.01,3.51,2.76h-2.16c-0.04-0.64-0.56-1.14-1.4-1.15 C282.58,5.67,282,6.04,282,6.83c0,1.94,5.02,0.86,5.02,4.27C287.02,12.63,285.79,14,283.56,14"
				/>
				<Polygon fill="#000000" points="3.61,4.26 9.11,4.26 9.11,21.68 20.1,21.68 20.1,25.89 3.61,25.89  " />
				<Path fill="#000000" d="M20.05,15.07c0-6.83,4.53-11.28,11.48-11.28s11.51,4.45,11.51,11.28s-4.57,11.28-11.51,11.28S20.05,21.9,20.05,15.07  M37.54,15.07c0-4.45-2.3-7.1-6.02-7.1c-3.68,0-5.98,2.66-5.98,7.1s2.26,7.1,5.98,7.1C35.27,22.18,37.54,19.52,37.54,15.07" />
				<Path fill="#000000" d="M58.71,22.52h-9.95l-1.37,3.37h-5.46l9.28-21.62h5.05l9.28,21.62h-5.46L58.71,22.52z M57.04,18.5l-3.27-7.97H53.7 l-3.27,7.97H57.04z" />
				<Path fill="#000000" d="M133.57,4.26v14.49c0,4.79-3.71,7.6-9.99,7.6c-6.31,0-9.99-2.81-9.99-7.6V4.26h5.5v14.18c0,2.53,1.45,3.74,4.49,3.74 c3.05,0,4.5-1.2,4.5-3.68V4.26H133.57z" />
				<Path fill="#000000" d="M111.76,15.08c0,6.83-4.57,11.27-11.51,11.27c-6.94,0-11.47-4.45-11.47-11.27c0-6.83,4.53-11.28,11.47-11.28 C107.2,3.8,111.76,8.25,111.76,15.08" />
				<Path fill="#000000" d="M75.81,4.26h-9.02v21.63h8.99c6.94,0,11.44-4.02,11.44-10.81C87.21,8.34,82.71,4.26,75.81,4.26 M75.77,21.69h-3.49V8.46 h3.49c3.64,0,5.94,2.22,5.94,6.61C81.71,19.49,79.41,21.69,75.77,21.69" />
				<Polygon fill="#000000" points="143.36,8.46 135.82,8.46 135.82,4.26 156.35,4.26 156.35,8.46 148.85,8.46 148.85,25.89 143.36,25.89  " />
			</G>
		</Svg>
	);
}
